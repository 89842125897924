#portal .container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}

.container .modal {
  z-index: 1;
  width: 90%;
  height: 90%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
}
.modal button {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.image-container {
  height: 90%;
  width: 90%;
}

.modal-image {
  width: 100%;
  object-fit: contain;
  mix-blend-mode: multiply;
  height: 100%;
}

.image-container-picture {
  display: block;
  max-width: 100%;
  height: 100%;
}


.search-input-wrapper {
  background: #E5E5E5;
  border-radius: 20px;
  height: 52px;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
}

.search-input-wrapper label {
  padding: 10px;
}

.search-input {
  padding-left: 20px;
  border: none;
  background: none;
  height: 100%;
  width: 100%;
}

.search-submit-button {
  position: absolute;
  top: -2000px;
  z-index: -100;
}

.search-filter-button {
  background: #C4C4C4;
  border-radius: 20px;
  height: 47px;
  width: 173px;
}

.search-filter-container {
  display: flex;
  align-items: center;
  margin: 100px 30px 0;
}

.search-filter-form {
  flex: 1;
  display: inline-flex;
  margin-right: 10px;
}

.photo-app-container {
  max-width: 1040px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

.search-filter-icon-button {
  display: none;
  background: #C4C4C4;
  border-radius: 20px;
  height: 47px;
  width: 65px;
  justify-content: center;
  align-items: center;
}

.group-label {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
  display: inline-flex;
}

.group-option-wrapper {
  padding-bottom: 40px;
  flex: 1 1 25%;
  min-width: 200px;
}

.clear-button {
  background: #DBDBDB;
  border-radius: 20px;
  width: 130px;
  height: 33px;
  font-size: 14px;
}

.clear-button-wrapper {
  display: flex;
  align-items: end;
  flex: 1 1 20%;
  justify-content: flex-end;
}

.network-status {
  margin-left: 30px;
  margin-top: 10px;
  color: rgba(188, 74, 60, 0.7);
  display: flex;
  align-items: center;
}

.offline-image {
  height: 15px;
  margin-right: 10px;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@media (max-width: 600px) {
  .search-filter-button {
    display: none;
  }

  .search-filter-icon-button {
    display: flex;
  }
}
